<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div>
          <img v-lazy="require('../assets/imgs/marketing/banner.webp')" alt="" />
        </div>
        <!-- 横幅内容区域 -->
        <h2 class="title">企业营销</h2>
        <p class="introduce">
          营销理念是企业营销活动的指导思想，是有效实现市场营销功能的基本条件
        </p>
      </div>
      <p class="title_h2">引流裂变</p>
      <span class="introduce_span">
        培育种子用户，通过导购和用户的连接、裂变等全员营销方式，吸粉到个人好友、社群等去沉淀流量
      </span>
      <div class="marketing_methods">
        <div class="methods_box1">
          <div class="img_sns">
            <img src="../assets/imgs/marketing/sns.png" />
          </div>
          <div class="social_details">
            <h3>社交营销</h3>
            <p>助力企业营销推广，低成本吸取优质流量</p>
          </div>
        </div>

        <div class="methods_box1">
          <div class="img_sns">
            <img src="../assets/imgs/marketing/spread.png" />
          </div>
          <div class="social_details">
            <h3>全员营销传播</h3>
            <p>主题场景打造、产品研发、运营体系构建</p>
          </div>
        </div>

        <div class="methods_box1 methods_box3">
          <div class="img_sns">
            <img src="../assets/imgs/marketing/content_.png" />
          </div>
          <div class="social_details">
            <h3>内容营销传播</h3>
            <p class="inline">创建二维码海报、加好友/群送红包</p>
            <p class="inline">
              创建内容（领取文章，生成微信文章，主推产品，自定义图文)
            </p>
          </div>
        </div>

        <div class="methods_box1 methods_box4">
          <div class="img_sns">
            <img src="../assets/imgs/marketing/fission.png" />
          </div>
          <div class="social_details">
            <h3>裂变式传播</h3>
            <p class="inline">发布任务（客户标签筛选，裂变海报，群发推送）</p>
            <p class="inline">
              用户参与（参与任务，生成海报，转发裂变，兑换奖品）
            </p>
          </div>
        </div>
      </div>
      <!-- 服务留存区域 -->
      <div class="service">
        <h3>服务留存</h3>
        <p>
          通过识别用户，标签化用户，精准的欢迎语和个性化服务，通过知识库和话术推荐，提升用户服务体验
        </p>
        <div class="service_bottom">
          <div class="images">
            <img v-lazy="require('../assets/imgs/marketing/service12.webp')" />
          </div>
          <div class="span_list">
            <div class="spans">
              <span :class="item.name" v-for="(item, index) in service" :key="index">{{ item.details }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 促活运营模块 -->
      <div class="operating">
        <h3>促活运营</h3>
        <p>
          通过个人/群的运营工具，提升群活跃度，发布品牌软文，加强用户心智，通过群控工具，保证社群的长治久安
        </p>
        <div class="box_item">
          <div>
            <img src="../assets/imgs/marketing/Increased_activity.png" />
            <h5>提高活跃度</h5>
            <span>
              通过群发助手，根据添加人以及标签筛选目标客户，通过文字、图片、网页以及小程序的内容形式，通知成员去群发给目标客户
            </span>
          </div>
          <div>
            <img src="../assets/imgs/marketing/friend_interaction.png" />
            <h5>提高曝光和好友互动</h5>
            <span>
              集成员工朋友圈管理，客户从员工企业微信信息界面直达历史朋友圈记录，从而看到过往企业精彩内容，新增一个精准的内容营销渠道，可进行朋友圈内容互动及查看记录查询
            </span>
          </div>
          <div>
            <img src="../assets/imgs/marketing/security_audit.png" />
            <h5>安全审计</h5>
            <span>
              企业可获取开启范围内员工的工作沟通内容，经外部联系人同意后，企业可获取外部联系人与开启范围内员工的会话内容存档，员工与内外部单聊、群聊的记录内容合规存档，包括消息、图片、附件等形式，支持下载导出
            </span>
          </div>
        </div>
      </div>
      <!-- 转化复购模块 -->
      <div class="conversion">
        <h4>转化复购</h4>
        <span>
          过领先和客户跟进工具，帮助导购和群运营人员管理用户，在了解用户之上，能够快速进行促成单转化，结合商城和直播，改变方法路径
        </span>
        <div class="conversion_item">
          <div>
            <img src="../assets/imgs/marketing/check.png" />
            <span>用户留资查看</span>
          </div>
          <div>
            <img src="../assets/imgs/marketing/cargo.png" />
            <span>私域流量直播带货</span>
          </div>
          <div>
            <img src="../assets/imgs/marketing/ascension.png" />
            <span>提升转化/老客复购</span>
          </div>
        </div>
      </div>
      <!-- 复盘提升模块 -->
      <div class="analyse">
        <h3>复盘提升</h3>
        <span>
          在引流，服务，运营，运营，再购等各方面，留存数据，分析，通过数据交换打通，促进各个区域的转化，减少流失，同时安全合规的运营用户
        </span>
        <div class="analyse_box">
          <div class="box_main">
            <div class="img_box">
              <img src="../assets/imgs/marketing/check_analysis.png" />
            </div>
            <p>全面复盘分析，优化运营</p>
          </div>
          <div class="box_main">
            <div class="img_box">
              <img src="../assets/imgs/marketing/data_grand.png" />
            </div>
            <p>全方位数据战报</p>
          </div>
          <div class="box_main">
            <div class="img_box">
              <img src="../assets/imgs/marketing/session.png" />
            </div>
            <p>更智能的社交会话分析，挖掘商业价值</p>
          </div>
          <div class="box_main">
            <div class="img_box">
              <img src="../assets/imgs/marketing/channel.png" />
            </div>
            <p>社交传播渠道，优化渠道</p>
          </div>
          <div class="box_main">
            <div class="img_box">
              <img src="../assets/imgs/marketing/kanban.png" />
            </div>
            <p>数据看板，销售实时掌握销售数据</p>
          </div>
          <div class="box_main">
            <div class="img_box">
              <img src="../assets/imgs/marketing/access.png" />
            </div>
            <p>销售人员查看用户访问/内容传播统计</p>
          </div>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "marketing",
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      service: [
        { name: "spn1", details: "自动化标签/语义标签，了解顾客，更好的服务" },
        { name: "spn2", details: "提升服务响应力，提高客户满意度" },
        {
          name: "spn3",
          details: "营销/服务一体化，结合机器人，提升客户留存率",
        },
        {
          name: "spn4",
          details: "个人统一形象，实时在线沟通（甚至是非好友关系）",
        },
      ],
    };
  },
  mounted () { },
  methods: {},
};
</script>
<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  .banner {
    width: 100%;
    height: 600px;
    color: #fff;
    position: relative;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      // loading大小
      img[lazy='loading'] {
        width: 200px;
      }
      // 实际图片大小
      img[lazy='loaded'] {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      height: 67px;
      font-size: 48px;
      font-weight: 500;
      line-height: 67px;
      letter-spacing: 3px;
      text-align: left;
      position: absolute;
      left: 361px;
      top: 230px;
    }
    .introduce {
      width: 565px;
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
      text-align: left;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 361px;
      top: 321px;
    }
  }
  .introduce,
  .title {
    animation: sport 0.5s;
    transform: translateY(0px);
  }
  //设置头部文字动画效果
  @keyframes sport {
    0% {
      transform: translateY(80px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  //版心
  .title_h2 {
    font-size: 40px;
    font-weight: 500px;
    color: #333;
    letter-spacing: 1px;
    margin-bottom: 24px;
    margin-top: 80px;
  }
  .introduce_span {
    display: block;
    margin-bottom: 60px;
    font-size: 20px;
    font-weight: 400px;
    color: #565656;
    letter-spacing: 1px;
  }
  .marketing_methods {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1200px;
    height: 330px;
    margin: auto;
    margin-bottom: 100px;
    .methods_box1 {
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      text-align: left;
      width: 585px;
      height: 150px;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      .img_sns {
        margin-left: 42px;
        width: 64px;
        height: 64px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .social_details {
        margin-left: 24px;
        h3 {
          height: 28px;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
        }
        p {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
      }
    }
    .methods_box3,
    .methods_box4 {
      margin-top: 30px;
    }
  }

  .inline {
    margin-top: 8px;
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #007aff;
      margin-right: 5px;
    }
  }
  // 服务留存css
  .service {
    height: 780px;
    width: 100%;
    background-color: #f6f8fb;
    padding-top: 80px;
    h3 {
      font-size: 40px;
      font-weight: 500;
      color: #333333;
      letter-spacing: 1px;
      margin: 0 0 24px 0;
    }
    p {
      width: 100%;
      font-size: 20px;
    }
  }
  .service_bottom {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 1200px;
    height: 420px;
    margin: auto;
    margin-top: 60px;
    background-color: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    .images {
      width: 540px;
      height: 420px;
      display: flex;
      justify-content: center;
      align-items: center;
      // loading大小
      img[lazy='loading'] {
        width: 150px;
      }
      // 实际图片大小
      img[lazy='loaded'] {
        width: 100%;
        height: 100%;
      }
    }
    .span_list {
      position: relative;
      width: 660px;
      height: 420px;
      text-align: left;
      .spans {
        padding-left: 62px;
        height: 236px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        span {
          display: block;
          font-size: 20px;
          font-weight: 400;
          color: #565656;
          width: 600px;
          height: 32px;
          margin-bottom: 36px;
        }
        .spn4 {
          margin-bottom: 0;
        }
      }
    }
  }
  .spn1::before,
  .spn2::before,
  .spn3::before,
  .spn4::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    vertical-align: middle;
  }
  .spn1::before {
    background: url('../assets/imgs/marketing/automation.png') no-repeat center;
    background-size: cover;
  }
  .spn2::before {
    background: url('../assets/imgs/marketing/service_response.png') no-repeat
      center;
    background-size: cover;
  }
  .spn3::before {
    background: url('../assets/imgs/marketing/integration.png') no-repeat center;
    background-size: cover;
  }
  .spn4::before {
    background: url('../assets/imgs/marketing/unified.png') no-repeat center;
    background-size: cover;
  }
  // 促活运营模块 css
  .operating {
    padding-top: 80px;
    h3 {
      font-size: 40px;
      font-weight: 500;
      color: #333;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: #565656;
      letter-spacing: 1px;
    }
    .box_item {
      width: 1200px;
      display: flex;
      margin: auto;
      margin-top: 60px;
      margin-bottom: 100px;
      justify-content: space-between;
      div {
        width: 380px;
        height: 388px;
        background-color: #ffffff;
        box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
        img {
          width: 64px;
          margin-top: 64px;
        }
        h5 {
          margin-top: 24px;
          margin-bottom: 15px;
          font-size: 20px;
          font-weight: 500;
          color: #333;
        }
        span {
          display: block;
          width: 308px;
          text-align: left;
          margin: auto;
          font-size: 16px;
          font-weight: 400;
          color: #999;
          letter-spacing: 1px;
        }
      }
    }
  }
  .conversion {
    width: 100%;
    height: 550px;
    background-color: #f6f8fb;
    padding-top: 80px;
    h4 {
      font-size: 40px;
      font-weight: 500;
      color: #333;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
    span {
      display: block;
      margin: auto;
      width: 975px;
      font-size: 20px;
      font-weight: 400;
      color: #565656;
      letter-spacing: 1px;
      margin-bottom: 60px;
    }
  }
  .conversion_item {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    div {
      display: flex;
      align-items: center;
      width: 380px;
      height: 150px;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    }
    img {
      height: 64px;
      margin-left: 42px;
    }
    span {
      display: inline-block;
      margin: 0;
      text-align: left;
      margin-left: 24px;
      font-size: 20px;
      font-weight: 500;
      color: #333;
    }
  }
  .analyse {
    width: 100%;
    padding-top: 95px;
    h3 {
      font-size: 40px;
      font-weight: 500;
      color: #333;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
    span {
      display: block;
      width: 968px;
      margin: auto;
      font-size: 20px;
      font-weight: 400;
      color: #565656;
      letter-spacing: 1px;
    }
  }
  .analyse_box {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 100px;
    margin-top: 60px;
    .box_main {
      display: flex;
      align-items: center;
      width: 585px;
      height: 150px;
      margin-bottom: 30px;
      background-color: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 6px 6px;
      .img_box {
        width: 48px;
        height: 48px;
        margin-left: 52px;
        margin-right: 36px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin: 0;
        text-align: left;
        font-weight: 500;
        color: #333;
        font-size: 20px;
        color: #333333;
      }
    }
  }
</style>
